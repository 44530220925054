/*variables*/
:root{

  --red: #ff0000;
  --redLight: #ff4848;


  --black: #000000;
  --white: #eeeeee;

  --gray1: hsl(0, 0%, 90%);
  --gray2: hsl(0, 0%, 70%);
  --gray3: hsl(0, 0%, 50%);
  --gray4: hsl(0, 0%, 30%);
  --gray5: hsl(0, 0%, 10%);


  --dulledImage: grayscale(0.1) sepia(0.2);
  --normalImage: grayscale(0) sepia(0);


  --valid: #14b830;
  --invalid: #e61919;
  --warn: #edc645;

  --sectionInnerMaxWidth: 1200px;

  --elevation0: none;
  --elevation1: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  --elevation2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  --elevation3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  --elevation4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --elevation5: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  --elevation6: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  --elevation7: 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  --elevation8: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  --elevation9: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  --spacing1: 0.146rem;
  --spacing2: 0.236rem;
  --spacing3: 0.382rem;
  --spacing4: 0.618rem;
  --spacing5: 1rem;
  --spacing6: 1.618rem;
  --spacing7: 2.618rem;
  --spacing8: 4.235rem;
  --spacing9: 6.854rem;
  --spacing10: 11.089rem;
  --spacing11: 17.942rem;
  --spacing12: 29.03rem;

  --fontSize0: 0.625rem;
  --fontSize1: 0.8125rem;
  --fontSize2: 1rem;
  --fontSize3: 1.25rem;
  --fontSize4: 1.625rem;
  --fontSize5: 2.0625rem;
  --fontSize6: 2.625rem;
  --fontSize7: 4.226rem;
  --fontSize8: 6.804rem;
  --fontSize9: 10.95rem;


  --primaryFont: "Raleway", sans-serif;
  --secondaryFont: "Cinzel", serif;

  --curve: 0;

  --lineHeight: 1.6em;
  --copyMaxWidth: 600px;
}
