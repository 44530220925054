h1{
  margin: var(--spacing7) 0 var(--spacing6) 0;

  color: var(--yellow);

  font-size: var(--fontSize6);
  font-family: var(--secondaryFont);
  line-height: 1.3em;
  @media (max-width: 800px), (max-height: 800px){
    margin: var(--spacing7) 0 var(--spacing7) 0;

    font-size: var(--fontSize5);
  }
  @media (max-width: 600px), (max-height: 600px){
    margin: var(--spacing7) 0 var(--spacing6) 0;

    font-size: var(--fontSize4);
  }

  &:first-child{
    margin-top: 0;
  }
}

h2{
  margin: var(--spacing7) 0 var(--spacing5) 0;

  color: var(--yellow);

  font-weight: 700;

  font-size: var(--fontSize4);
  font-family: var(--secondaryFont);
  line-height: 1.3em;
  @media (max-width: 600px), (max-height: 600px){
    margin: var(--spacing7) 0 var(--spacing5) 0;

    font-size: var(--fontSize3);
  }

  &:first-child{
    margin-top: 0;
  }
}

h3{
  margin: var(--spacing6) 0 var(--spacing4) 0;

  color: var(--yellow);

  font-weight: 700;

  font-size: var(--fontSize3);
  font-family: var(--secondaryFont);
  line-height: 1.3em;
  @media (max-width: 800px), (max-height: 800px){
    margin: var(--spacing6) 0 var(--spacing4) 0;

    font-size: var(--fontSize2);
  }

  &:first-child{
    margin-top: 0;
  }
}

h4{
  margin: var(--spacing5) 0 var(--spacing3) 0;

  color: var(--yellow);

  font-weight: 700;

  font-size: var(--fontSize2);
  font-family: var(--secondaryFont);
  line-height: 1.3em;

  &:first-child{
    margin-top: 0;
  }
}
