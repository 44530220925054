@mixin gold-border{
  border-style: solid;
  border-image: linear-gradient(22deg, var(--brown), var(--yellow), var(--brown)) 1;
}
@mixin gold-separator{
  border-top: 1px solid;
  border-image: linear-gradient(22deg, var(--brown), var(--yellow), var(--brown)) 1;
}
@mixin gold-background{
  background: linear-gradient(22deg, var(--brown), var(--yellow), var(--brown));
}
