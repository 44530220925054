/*latin*/
@font-face{
  font-weight: 700;
  font-family: "Cinzel";
  font-style: normal;
  src: url("/fonts/8vIU7ww63mVu7gtR-kwKxNvkNOjw-jHgfY3lDQ.woff2") format("woff2");
  font-display: block;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/*latin*/
@font-face{
  font-weight: 400;
  font-family: "Raleway";
  font-style: italic;
  src: url("/fonts/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format("woff2");
  font-display: block;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*latin*/
@font-face{
  font-weight: 700;
  font-family: "Raleway";
  font-style: italic;
  src: url("/fonts/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format("woff2");
  font-display: block;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*latin*/
@font-face{
  font-weight: 400;
  font-family: "Raleway";
  font-style: normal;
  src: url("/fonts/1Ptug8zYS_SKggPNyC0ITw.woff2") format("woff2");
  font-display: block;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*latin*/
@font-face{
  font-weight: 700;
  font-family: "Raleway";
  font-style: normal;
  src: url("/fonts/1Ptug8zYS_SKggPNyC0ITw.woff2") format("woff2");
  font-display: block;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
